<template lang="pug">
Popup.popup(
  size="sm"
  title="Мы используем куки"
  :showAdditionalButton="false"
  :showTwoAdditionalButton="false"
  mainButtonLabel="Хорошо"
  mainButtonColor="default"
  mainButtonSize="xxs"
  :disableFullWidthFirstButton="true"
  :hideCloseButton="true"
  :combineBodyAndFooter="true"
  :customHeaderTitle="true"
  :customBodyClass="true"
  :customMainButtonClass="true"
  @mainButtonClick="acceptAll"
  v-model="visible"
)
  .description
    | Узнать подробнее о том как это работает можно&nbsp;
    a(href="/privacy-policy") здесь
</template>

<script lang="ts">
import Popup from '@/components/ui/Popup/index.vue'

export default {
  components: { Popup },
  data() {
    const acceptAllCookies = useCookie<boolean>('acceptAllCookies');

    return {
      visible: !acceptAllCookies.value,
    }
  },
  methods: {
    acceptAll () {
      const acceptAllCookies = useCookie<boolean>('acceptAllCookies');
      acceptAllCookies.value = true;
      this.visible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.popup {
  bottom: get-vw(18);
}

.step {
  @include text-t4-regular;
}

.step:deep(p) {
  margin: 0;
}

.policy {
  color: $links;
  text-decoration: underline;
}

.content {
  margin-bottom: get-vw(36);
}

.checkbox {
  margin-bottom: get-vw(12);
}

.properties {
  margin-bottom: get-vw(36);
}

@include tablet {
  .popup {
    left: get-vw(100, 'md');
  }
  .step {
    @include text-t4-regular-m;
  }
  .content {
   margin-bottom: get-vw(22, "md");
  }
  .properties {
    margin-bottom: get-vw(31, "md");
  }
}

@include mobile {
  .popup {
    left: 0;
    bottom: get-vw(40, "sm");
  }
}

</style>
